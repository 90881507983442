exports.shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
  ...rest
}) => {
  if (routerProps?.location?.pathname === prevRouterProps?.location?.pathname) {
    return false;
  }
  const currentPosition = getSavedScrollPosition(routerProps.location);
  return currentPosition || true;
};
