// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microphone-placement-js": () => import("./../../../src/pages/microphone-placement.js" /* webpackChunkName: "component---src-pages-microphone-placement-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-room-size-js": () => import("./../../../src/pages/room-size.js" /* webpackChunkName: "component---src-pages-room-size-js" */),
  "component---src-pages-seating-js": () => import("./../../../src/pages/seating.js" /* webpackChunkName: "component---src-pages-seating-js" */)
}

